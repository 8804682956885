.label {
  @apply text-sm text-[#2B2B2B];
}

.isFilled {
  opacity: 1;
  transform: translateY(0);
}

.input {
  @apply bg-white rounded-md text-sm w-full border p-4 mt-2;
  @apply focus:ring-[#863DC4] focus:border-[#863DC4];
  @apply focus:outline-none focus:ring-1;
  @apply focus:bg-[#F9F6FC];
  transition-property: padding;
  transition-duration: 150ms;
}

.isFuled {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.custom-select {
  @apply bg-white rounded-md text-sm w-full border border-solid border-[#C8C8C8] mt-2 indent-4 h-[55px];
  @apply focus:border-[#863DC4];
  @apply focus:outline-none focus:ring-0;
  @apply focus:bg-[#F9F6FC];
  -webkit-appearance: none;
  transition-property: padding;
  transition-duration: 150ms;
  background: url('../../../../public/ic_arrow_down.svg') no-repeat 96% 50% #fefefe;
}

.custom-select:focus {
  background: url('../../../../public/ic_arrow_down.svg') no-repeat 96% 50% #f9f6fc;
}

/* For mozila */
@-moz-document url-prefix() {
  .custom-select {
    text-indent: 5px;
  }
}
