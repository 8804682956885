@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('../public/fonts/Satoshi-Variable.woff2') format('woff2'),
      url('../public/fonts/Satoshi-Variable.woff') format('woff'),
      url('../public/fonts/Satoshi-Variable.ttf') format('truetype'),
      url('../public/fonts/Satoshi-Variable.eot') format('embedded-opentype');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('../public/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
      url('../public/fonts/Satoshi-VariableItalic.woff') format('woff'),
      url('../public/fonts/Satoshi-VariableItalic.ttf') format('truetype'),
      url('../public/fonts/Satoshi-VariableItalic.eot') format('embedded-opentype');
    font-weight: 300 900;
    font-display: swap;
    font-style: italic;
  }
}

.input-container {
  position: relative;
  display: flex;
  background: black;
  width: 100%;
  padding: 0;
}

.calendar-icon {
  background-image: url('./assets/icons/calendar.svg');
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 18px;
}
